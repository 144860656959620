import request from '@/utils/request';

/* 设计方案API */

// 获取柜类方案菜单
export function apiGetCabinetSchemeMenu() {
  return request({
    url: '/MyFavorite/menu',
    method: 'post'
  });
};

// 获取柜类方案列表
export function apiGetCabinetSchemeList(data) {
  return request({
    url: '/MyFavorite/mydesign',
    method: 'post',
    data: data
  });
};

// 获取门板方案列表
export function apiGetPlankSchemeList(data) {
  return request({
    url: '/CabComp/List',
    method: 'post',
    data: data
  });
};

// 删除柜类方案
export function apiDeleteCabinetScheme(data) {
  return request({
    url: '/MyFavorite/delDesign',
    method: 'post',
    data: data
  });
};

// 删除门板方案
export function apiDeletePlankScheme(data) {
  return request({
    url: '/CabComp/Del',
    method: 'post',
    data: data
  });
};
