<template>
  <div>
    <TopBar v-if="!$route.meta.hideTopBar" />
    <router-view v-slot="{ Component }">
      <keep-alive >
        <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.name"  />
      </keep-alive>
      <component v-if="!$route.meta.keepAlive" :is="Component" :key="$route.name" />
    </router-view>
    
    <BottomBar v-if="!$route.meta.hideBottomBar" />
    <div class='login-wrap' v-if="loginWin">
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>登 录</span>
            <el-button link @click="closeLoginWin">
              关闭
            </el-button>
          </div>
        </template>
        <el-form ref="ruleFormRef" :model="ruleForm" status-icon label-width="80px" class="demo-ruleForm">
          <el-form-item label="手机号码" prop="phoneNo">
            <el-input v-model.number="ruleForm.phoneNo" />
          </el-form-item>
          <el-row>
            <el-col :span="17">
              <el-form-item label="验证码" prop="code">
                <el-input v-model="ruleForm.code" oninput="if(value.length>6)value=value.slice(0,6)" />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-button @click="getVerifyCode" v-bind:disabled="!verifyCodeBtnState" style="float:right;width:95%;">{{
                verifyCodeBtnText }}</el-button>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button type="primary" @click="loginIn"> 登 录 </el-button>
            <el-button @click="closeLoginWin"> 取 消 </el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
  <!-- <header v-if="!$route.meta.hideNav">
  <div id="nav">
    <div class="nav-logo">
      <router-link to="/"><img src="@/assets/images/logo.jpg"></router-link>
    </div>
    <ul class="nav-item">
      <li v-for="(item,index) in topNav" :key="index">
        <router-link  :to="{ path: `${item.Url ? item.Url : ''}`}">{{item.Name}}</router-link>
        <img :src='navArrow' class="arrow" v-if="!item.Url" />
        <ul v-if="!item.Url">
          <li v-for="(item_sec,index_sec) in item.data" :key="index_sec">
            <router-link  :to="{ path: `${item_sec.Url}`}">{{item_sec.Name}}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div class="nav-other">
    <el-button-group>
      <ShoppingCartPart></ShoppingCartPart>
    </el-button-group>
    <el-button v-if="!loginState" class="login-btn" link>登录</el-button>
  </div>
  <div class="nav-contact">
    <router-link to="/contact/19">寻找阅美</router-link>
  </div>
  <div class="handheld-nav-btn" @click="showNav">
    <div>菜单 <img src="@/assets/images/icon_menu.svg" /></div>
  </div>
  <div class="handheld-nav" :class="{'on' : showHandheldNav}">
    <div class="handheld-shade"></div>
    <i class="handheld-nav-back iconfont icon-fanhui" v-if="showHandHeldSec" @click="showHandHeldSec=false"><text>菜单</text></i>
    <i class="handheld-nav-close iconfont icon-guanbi" @click="showNav"></i>
    <div class="handheld-nav-wrap">
      <div class="handheld-nav-head">
        <img src="@/assets/images/logo.jpg">
      </div>
      <div class="handheld-nav-body">
        <ul>
          <li v-for="(item,index) in topNav" :key="index">
            <a @click="showSecNav(item.Code)">
              <span v-if="item.Code === 'about'" class="iconfont icon-logo"></span>
              <span v-else-if="item.Code === 'design'" class="iconfont icon-peizhi"></span>
              <span v-else-if="item.Code === 'material'" class="iconfont icon-xinxingcailiao"></span>
              <span v-else-if="item.Code === 'equipment'" class="iconfont icon-shengchanshebei"></span>
              <span v-else-if="item.Code === 'news'" class="iconfont icon-theearth2diqiu"></span>
              {{item.Name}}
            </a>
            <i class="iconfont icon-xiangqian"></i>
          </li>
          <li @click="showNav">
            <router-link to="/contact/19"><span class="iconfont icon-dingwei"></span>寻找阅美</router-link>
          </li>
        </ul>
        <div class="handheld-secnav" :class="{'on' : showHandHeldSec}">
          <div class="handheld-secnav-title">
            {{activeNav.Name}}
          </div>
          <ul>
            <li v-for="(item,index) in activeNav.data" :key="index" @click="showNav">
              <router-link  :to="{ path: `${item.Url}`}">{{item.Name}}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header> -->
  <!-- <div>
  <router-view/>
  <footer class="footer" v-if="!$route.meta.hideBottomNav">
    <div id="bottom_nav">
      <div class="bottom-nav-item" v-for="(item,index) in bottomNav" :key="index">
        <div class="bottom-nav-title">{{item.Name}}</div>
          <ul v-if="!item.IsEnd">
            <li v-for="(item_sec,index_sec) in item.data" :key="index_sec">
              <router-link  :to="{ path: `${item_sec.Url}`}">{{item_sec.Name}}</router-link>
            </li>
          </ul>
      </div>
      <div class="bottom-nav-item">
        <div class="bottom-nav-title">寻找阅美</div>
        <ul>
          <li><router-link to="/contact/19">联系方式</router-link></li>
        </ul>
        <div class="bottom-nav-online">
          <i class="iconfont icon-headset"></i>
          <span>在线客服</span>
          <div class="nav-hide"><img src="@/assets/images/online.jpg"></div>
        </div>
        <div class="bottom-nav-guanzhu">关注阅美</div>
        <div class="guanzhu-wrap">
          <div class="guanzhu-item">
            <i class="iconfont icon-wechat"></i>
            <div class="nav-hide"><img src="@/assets/images/wechat.jpg"></div>
          </div>
          <div class="guanzhu-item">
            <i class="iconfont icon-xiaohongshu"></i>
            <div class="nav-hide"><img src="@/assets/images/xhs.jpg"></div>
          </div>
          <div class="guanzhu-item">
            <i class="iconfont icon-douyin"></i>
            <div class="nav-hide"><img src="@/assets/images/douyin.jpg"></div>
          </div>
        </div>
      </div>
    </div>
    <div id="bottom_sign">
      <div>©阅美家居（广东）有限公司  Copyright 2022</div>
      <div><a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备2022086158号</a></div>
      <div>技术支持：顶思信息技术</div>
    </div>
  </footer>
  <div class='login-wrap' v-if="loginWin">
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span>登 录</span>
          <el-button link @click="closeLoginWin">
            关闭
          </el-button>
        </div>
      </template>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        status-icon
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="手机号码" prop="phoneNo">
          <el-input v-model.number="ruleForm.phoneNo" />
        </el-form-item>
        <el-row>
          <el-col :span="17">
            <el-form-item label="验证码" prop="code">
              <el-input v-model="ruleForm.code" oninput="if(value.length>6)value=value.slice(0,6)" />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-button @click="getVerifyCode" v-bind:disabled="!verifyCodeBtnState" style="float:right;width:95%;">{{verifyCodeBtnText}}</el-button
            >
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="loginIn"> 登 录 </el-button>
          <el-button @click="closeLoginWin"> 取 消 </el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</div> -->
</template>

<script>
import { reactive, toRefs, onMounted,watch } from 'vue'
import { ElMessageBox } from 'element-plus'
import axios from 'axios'
import qs from 'qs'
import verify from '@/assets/js/verify'
import TopBar from '@/components/Bar/TopBar.vue';
import BottomBar from '@/components/Bar/BottomBar.vue';
// import ShoppingCartPart from './components/ShoppingCartPart'

export default ({
  components: {
    TopBar,
    BottomBar
    // ShoppingCartPart
  },
  setup() {
    const state = reactive({
      ruleForm: {
        phoneNo: '',
        code: ''
      },
      topNav: [],
      bottomNav: [],
      activeNav: [],
      loginWin: false,
      verifyCodeBtnText: '获取验证码',
      verifyCodeBtnState: true,
      showHandheldNav: false,
      showHandHeldSec: false
    })
    const getVerifyCode = () => {
      if (!verify.phoneNo(state.ruleForm.phoneNo)) {
        ElMessageBox.alert('请输入正确的手机号码', '提醒')
        return
      }

      state.verifyCodeBtnState = false
      verifyCodeBtn(59)
      const param = 'phoneNo=' + state.ruleForm.phoneNo

      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: 'http://api.yumeigd.com/account/verifycode',
        data: param
      }).then(function (res) {
        const data = res.data
        if (data.State === 'error') {
          ElMessageBox.alert(res.Msg, '提醒')
        }
      })
    }
    const verifyCodeBtn = (ii) => {
      state.verifyCodeBtnText = ii
      setTimeout(() => {
        if (ii === 1) {
          state.verifyCodeBtnText = '获取验证码'
          state.verifyCodeBtnState = true
        } else {
          ii--
          verifyCodeBtn(ii)
        }
      }, 1000)
    }

    onMounted(() => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: 'http://api.yumeigd.com/menu/webmenu'
      }).then(function (res) {
        const data = res.data.data
        state.topNav = data.filter(item => item.Code !== 'contact')
        state.bottomNav = data.filter(item => item.Code !== 'design' && item.Code !== 'contact')
      })
    })
    const showNav = () => {
      state.showHandheldNav = !state.showHandheldNav
      state.showHandHeldSec = false
    }
    const showSecNav = (code) => {
      state.showHandHeldSec = !state.showHandHeldSec
      state.activeNav = state.topNav.filter(item => item.Code === code)[0]
    }
    const setLoginWinDisplay = (val) => {
      state.loginWin = val
    }
    const getLoginInfo = () => {
      return state.ruleForm
    }
    const clearLoginInfo = () => {
      state.ruleForm.phoneNo = ''
      state.ruleForm.code = ''
    }
    return {
      ...toRefs(state),
      showNav,
      showSecNav,
      getVerifyCode,
      verifyCodeBtn,
      setLoginWinDisplay,
      getLoginInfo,
      clearLoginInfo
    }
  },
  mounted() {

  },
  computed: {
    loginWin() {
      return this.$store.state.loginWin
    }
  },
  watch: {
    loginWin: {
      immediate: true,
      handler: function (val) {
        this.setLoginWinDisplay(val)
      }
    }
  },
  methods: {
    loginIn() {
      const that = this
      const param = that.getLoginInfo()
      if (!verify.phoneNo(param.phoneNo)) {
        ElMessageBox.alert('请输入正确的手机号码', '提醒')
        return
      }
      if (!param.code) {
        ElMessageBox.alert('请输入验证码', '提醒')
        return
      }
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: 'http://api.yumeigd.com/account',
        data: qs.stringify(param)
      }).then(function (res) {
        const data = res.data
        if (data.State === 'error') {
          ElMessageBox.alert(data.Msg, '提醒')
        } else {
          that.$store.commit('setToken', data.Token)
          that.$store.commit('setUserPowerDealerDesign', data.UserPower.DealerDesign)
          that.$store.commit('setNickName', data.NickName)
          that.$store.commit('setUserCard', data.UserCard)
          that.$store.commit('setLoginWin', false)
          that.$store.commit('setPhone', param.phoneNo)
          that.clearLoginInfo()
        }
      })
    },
    closeLoginWin() {
      this.$store.commit('setLoginWin', false)
    }
  }
})
</script>

<style lang="scss">
@import "./assets/styles/index.scss"; //引入方式
</style>
