import axios from 'axios';
import qs from 'qs';
import store from '@/store';

// 创建axios实例
const service = axios.create({
  baseURL: '/api',
  timeout: 30000,
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded'
  },
  // transformRequest: [function (data) {
  //   return qs.stringify(data);
  // }],
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 发送请求之前的操作
    const token = store.getters.getToken;
    const userCard = store.getters.getUserCard;
    if (token) {
      config.headers['Custom-Token'] = token;
    }
    if (userCard) {
      config.headers['User-Card'] = userCard;
    }
    return config;
  },
  (error) => {
    // 请求错误之后的操作
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 响应成功的操作
    return response;
  },
  (error) => {
    // 响应错误的操作
    return Promise.reject(error);
  }
);

export default service;
