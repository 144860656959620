/* ***** 数字工具集 ***** */

// 数字转换为人民币格式
export function toCNY(num) {
  return num.toLocaleString('zh-CN', { style: "currency", currency: "CNY" });
};

// 数字转换为无货币符号人民币格式
export function toNoSymbolCNY(num) {
  return num.toLocaleString('zh-CN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

// 分隔数字(默认每3位分隔，默认分隔符为逗号)
export function separate(num, digit = 3, separator = ',') {
  const regex = new RegExp(`(\\d)(?=(?:\\d{${digit}})+$)`, 'g');
  let numParts = num.toString().split('.');
  numParts[0] = numParts[0].replace(regex, `$1${separator}`);
  return numParts.join('.');
};

export default {
  toCNY,
  toNoSymbolCNY,
  separate
};
