import request from '@/utils/request';

/* 购物车API */

// 购物车添加商品
export function apiAddGoodsToShoppingCart(data) {
  return request({
    url: '/Shopping/Add',
    method: 'post',
    data: data
  });
};

// 购物车删除商品
export function apiDeleteGoodsFromShoppingCart(data) {
  return request({
    url: '/Shopping/Del',
    method: 'post',
    data: data
  });
};

// 获取购物车商品列表
export function apiListShoppingCartGoods(data) {
  return request({
    url: '/Shopping/GetList',
    method: 'post',
    data: data
  });
};

// 更新购物车商品数量
export function apiUpdateShoppingCartGoodsQty(data) {
  return request({
    url: '/Shopping/UpdateQty',
    method: 'post',
    data: data
  });
};
