<template>
  <!-- 底部栏 -->
  <div class="bottom-bar">
    <!-- 导航 -->
    <div class="bottom-bar-nav">
      <div class="nav-item" v-for="menuItem in navMenu" :key="menuItem.Code">
        <h1>{{ menuItem.Name }}</h1>
        <ul>
          <li v-for="subMenuItem in menuItem.data" :key="subMenuItem.ID">
            <router-link :to="subMenuItem.Url">{{ subMenuItem.Name }}</router-link>
          </li>
        </ul>
      </div>
      <div class="nav-item">
        <h1>联系阅美</h1>
        <div class="contact">
          <i class="iconfont icon-headset"></i>
          <span>联系客服</span>
          <img src="@/assets/images/bottomBar/customer.png"/>
        </div>
        <h1>关注阅美</h1>
        <div class="follow">
          <div>
            <i class="iconfont icon-wechat"></i>
            <img src="@/assets/images/bottomBar/wechat.jpg"/>
          </div>
          <div>
            <i class="iconfont icon-xiaohongshu"></i>
            <img src="@/assets/images/bottomBar/redbook.png"/>
          </div>
          <div>
            <i class="iconfont icon-douyin"></i>
            <img src="@/assets/images/bottomBar/tiktok.png"/>
          </div>
        </div>
      </div>
    </div>
    <!-- 版权 -->
    <div class="bottom-bar-copyright">
      <span>Copyright © 2022-2024 阅美家居（广东）有限公司 All Rights Reserved.</span>&nbsp;
      <a href="http://beian.miit.gov.cn/">粤ICP备2022086158号</a>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onBeforeMount, onMounted } from 'vue';
import { apiListNavMenu } from '@/api/homePage';

const navMenu = ref([]);

onMounted(() => {
  apiListNavMenu().then(res => {
    navMenu.value = res.data.data.filter(item => item.Code !== 'design' && item.Code !== 'contact');
  });
});

</script>

<style lang="scss" scoped>
.bottom-bar {
  padding: 40px 10%;
  color: white;
  background-color: #333333;
  .bottom-bar-nav {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid grey;
    display: flex;
    justify-content: space-between;
    .nav-item {
      flex: 1;
      h1 {
        margin: 10px 0;
        font-size: 14px;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
          margin-bottom: 5px;
          a {
            font-size: 12px;
          }
          a:hover {
            font-weight: bold;
          }
        }
      }
      .contact, .follow {
        img {
          width: 180px;
          border: 1px solid grey;
          position: absolute;
          bottom: calc(100% + 1px);
          left: 50%;
          transform: translateX(-50%);
          z-index: 10;
          display: none;
        }
      }
      .contact {
        width: 140px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid rgb(242,242,242,0.2);
        background-color: rgb(0,0,0,0.4);
        position: relative;
        text-align: center;
        cursor: pointer;
        i {
          margin-right: 3px;
          font-size: 32px;
          vertical-align: middle;
        }
        span {
          font-size: 16px;
          vertical-align: middle;
        }
      }
      .contact:hover {
        border-color: white;
        img {
          display: block;
        }
      }
      .follow {
        display: flex;
        justify-content:space-between;
        width:11rem;
        div {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 1px solid rgb(242,242,242,0.2);
          background-color: rgb(0,0,0,0.4);
          position: relative;
          line-height: 48px;
          text-align: center;
          cursor: pointer;
          i {
            font-size: 24px;
          }
        }
        div:hover {
          border-color: white;
          img {
            display: block;
          }
        }
      }
    }
  }

  .bottom-bar-copyright {
    font-size: 12px;
    text-align: center;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

// 电脑
@media (min-width: 1200px) {
}

// 平板
@media (min-width: 768px) and (max-width: 1199px) {
  .bottom-bar {
    .bottom-bar-nav {
      display: none;
    }
  }
}

// 手机
@media (max-width: 767px) {
  .bottom-bar {
    .bottom-bar-nav {
      display: none;
    }
  }
}
</style>
