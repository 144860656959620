import request from '@/utils/request';

/* 首页API */

export function apiListNavMenu() {
  return request({
    url: '/menu/webmenu',
    method: 'post'
  });
};

export function apiListHomeData() {
  return request({
    url: '/home/data',
    method: 'post'
  });
};
